document.addEventListener('DOMContentLoaded', () => {
  // modal
  const contentModal = document.querySelector('.modal--content');
  const thanksModalMain = document.querySelector(
    '.modal-thanks--main'
  );
  const thanksModal = document.querySelector('.modal--thanks');
  const modal2 = document.querySelector('.modal--2');

  const closeModal = document.querySelectorAll('.modal__close');
  const input = document.querySelectorAll('.modal__form-input');
  const modal = document.querySelectorAll('.modal');
  const modalNameMain = document.querySelector(
    '.modal__main__form-input--name'
  );
  const modalPhoneMain = document.querySelector(
    '.modal__main__form-input--phone'
  );
  const modalNameSecond = document.querySelector(
    '.modal__second__form-input--name'
  );
  const modalPhoneSecond = document.querySelector(
    '.modal__second__form-input--phone'
  );

  const modal3 = document.querySelector('.modal--3');

  const openModel3Button = document.querySelectorAll(
    '.btn__open-modal3'
  );

  const submitModalThird = document.querySelector(
    '.modal__third__content-btn'
  );

  const modalNameThird = document.querySelector(
    '.modal__modal3__form-input--name'
  );
  const modalPhoneThird = document.querySelector(
    '.modal__modal3__form-input--phone'
  );

  modal.forEach((item) => {
    item.addEventListener('click', (e) => {
      if (e.target.matches('.modal--active')) {
        modal2.classList.remove('modal--active');
        modal3.classList.remove('modal--active');
        contentModal.classList.remove('modal--active');
        thanksModal.classList.remove('modal--active');
        thanksModalMain.classList.remove('modal--active');
        body.style.overflow = 'auto';
        input.forEach((item) => {
          item.value = '';
          item.classList.remove('error');
          item.classList.remove('novalid');
        });
      }
    });
  });

  const submitModalMain = document.querySelector(
    '.sumbit__main__modal'
  );

  const contentOpenModalButtons = document.querySelectorAll(
    '.btn__open-modal'
  );
  const modal2OpenModalButtons = document.querySelectorAll(
    '.btn__open-modal2'
  );
  const submitModalSecond = document.querySelector(
    '.modal__second__content-btn'
  );

  const burgerMenu = document.querySelectorAll('.page-menu__wrap');
  const body = document.body;

  function openModal() {
    contentModal.classList.add('modal--active');
    burgerMenu[0].classList.remove('page-menu__wrap--active');
    body.style.overflow = 'hidden';
  }

  function closeModals() {
    thanksModal.classList.remove('modal--active');
    contentModal.classList.remove('modal--active');
    thanksModalMain.classList.remove('modal--active');
    modal2.classList.remove('modal--active');
    modal3.classList.remove('modal--active');
    body.style.overflow = 'auto';
    input.forEach((item) => {
      item.value = '';
    });
  }

  closeModal.forEach((elem) => {
    elem.addEventListener('click', closeModals);
  });

  contentOpenModalButtons.forEach((contentOpenModalButton) => {
    contentOpenModalButton.addEventListener('click', function () {
      const contentModal = document.querySelector(
        `#${this.getAttribute('data-modal-id')}`
      );
      openModal(contentModal);
    });
  });

  modal2OpenModalButtons.forEach((button) => {
    button.addEventListener('click', function () {
      openModal2();
    });
  });

  const closeButton2 = document.querySelector(
    '.modal__close--content2'
  );

  closeButton2.addEventListener('click', () => {
    thanksModal.classList.remove('modal--active');
    modal2.classList.remove('modal--active');
    body.style.overflow = 'auto';
  });

  function openModal2() {
    modal2.classList.add('modal--active');
    burgerMenu[0].classList.remove('page-menu__wrap--active');
    body.style.overflow = 'hidden';
    input.forEach((item) => {
      item.value = '';
    });
  }

  ///Modal1Name
  modalNameMain.oninput = function () {
    if (modalNameMain.value.length < 2) {
      modalNameMain.classList.add('novalid');
    } else {
      modalNameMain.classList.remove('novalid');
      modalNameMain.classList.remove('error');
    }
  };

  ///modal1Phone
  modalPhoneMain.oninput = function () {
    if (modalPhoneMain.value.length < 16) {
      modalPhoneMain.classList.add('novalid');
    } else {
      modalPhoneMain.classList.remove('novalid');
      modalPhoneMain.classList.remove('error');
    }
  };

  ///Send Form Modal1
  submitModalMain.addEventListener('click', (e) => {
    if (
      modalPhoneMain.value.length < 16 ||
      modalNameMain.value.length < 2
    ) {
      if (modalNameMain.value.length < 2)
        modalNameMain.classList.add('error');
      if (modalPhoneMain.value.length < 16)
        modalPhoneMain.classList.add('error');
    } else {
      contentModal.classList.remove('modal--active');
      thanksModalMain.classList.add('modal--active');
      body.style.overflow = 'hidden';
      setTimeout(() => {
        modalNameMain.value = '';
        modalPhoneMain.value = '';
        body.style.overflow = 'auto';
        thanksModalMain.classList.remove('modal--active');
        ///Send data on mail
      }, 2000);
    }
  });

  ///Modal2Name
  modalNameSecond.oninput = function () {
    if (modalNameSecond.value.length < 2) {
      modalNameSecond.classList.add('novalid');
    } else {
      modalNameSecond.classList.remove('novalid');
      modalNameSecond.classList.remove('error');
    }
  };

  ///modal2Phone
  modalPhoneSecond.oninput = function () {
    if (modalPhoneSecond.value.length < 16) {
      modalPhoneSecond.classList.add('novalid');
    } else {
      modalPhoneSecond.classList.remove('novalid');
      modalPhoneSecond.classList.remove('error');
    }
  };

  ///Send Form Modal2
  submitModalSecond.addEventListener('click', (e) => {
    if (
      modalPhoneSecond.value.length < 16 ||
      modalNameSecond.value.length < 2
    ) {
      if (modalNameSecond.value.length < 2)
        modalNameSecond.classList.add('error');
      if (modalPhoneSecond.value.length < 16)
        modalPhoneSecond.classList.add('error');
    } else {
      modal2.classList.remove('modal--active');
      thanksModal.classList.add('modal--active');
      body.style.overflow = 'hidden';
      setTimeout(() => {
        modalNameSecond.value = '';
        modalPhoneSecond.value = '';
        body.style.overflow = 'auto';
        thanksModal.classList.remove('modal--active');
        ///Send data on mail
      }, 2000);
    }
  });

  ///Modal3

  function openModal3() {
    modal3.classList.add('modal--active');
    burgerMenu[0].classList.remove('page-menu__wrap--active');
    body.style.overflow = 'hidden';
    input.forEach((item) => {
      item.value = '';
    });
  }

  openModel3Button.forEach((button) => {
    button.addEventListener('click', function () {
      openModal3();
    });
  });

  modalNameThird.oninput = function () {
    if (modalNameThird.value.length < 2) {
      modalNameThird.classList.add('novalid');
    } else {
      modalNameThird.classList.remove('novalid');
      modalNameThird.classList.remove('error');
    }
  };

  ///modal3Phone
  modalPhoneThird.oninput = function () {
    if (modalPhoneThird.value.length < 16) {
      modalPhoneThird.classList.add('novalid');
    } else {
      modalPhoneThird.classList.remove('novalid');
      modalPhoneThird.classList.remove('error');
    }
  };

  ///Send Form Modal3
  submitModalThird.addEventListener('click', (e) => {
    if (
      modalPhoneThird.value.length < 16 ||
      modalNameThird.value.length < 2
    ) {
      if (modalNameThird.value.length < 2)
        modalNameThird.classList.add('error');
      if (modalPhoneThird.value.length < 16)
        modalPhoneThird.classList.add('error');
    } else {
      modal3.classList.remove('modal--active');
      thanksModal.classList.add('modal--active');
      body.style.overflow = 'hidden';
      setTimeout(() => {
        modalNameThird.value = '';
        modalPhoneThird.value = '';
        body.style.overflow = 'auto';
        thanksModal.classList.remove('modal--active');
        ///Send data on mail
      }, 2000);
    }
  });
});
