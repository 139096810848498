document.addEventListener('DOMContentLoaded', () => {
  const { MaskInput } = Maska;

  new MaskInput('[data-maska]');

  let pageBanner = new Swiper('.page-banner__swiper', {
    autoplay: {
      delay: 10000,
    },
    navigation: {
      nextEl: '.page-banner__swiper-next',
      prevEl: '.page-banner__swiper-prev',
    },
  });

  ///Validation Form
  const inputNamePageBanner = document.querySelector(
    '.page-banner__form-input'
  );
  const inputPhonePageBanner = document.querySelector(
    '.page-banner__form-input-phone'
  );
  const buttonSubmitPageBanner = document.querySelector(
    '.page-banner__form-btn'
  );

  const modalThanksPageBanner =
    document.querySelector('.modal--thanks');

  const body = document.body;

  buttonSubmitPageBanner.addEventListener('click', (e) => {
    if (
      inputPhonePageBanner.value.length < 16 ||
      inputNamePageBanner.value.length < 2
    ) {
      if (inputNamePageBanner.value.length < 2)
        inputNamePageBanner.classList.add('error');
      if (inputPhonePageBanner.value.length < 16)
        inputPhonePageBanner.classList.add('error');
    } else {
      modalThanksPageBanner.classList.add('modal--active');
      body.style.overflow = 'hidden';
      setTimeout(() => {
        inputNamePageBanner.value = '';
        inputPhonePageBanner.value = '';
        body.style.overflow = 'auto';
        modalThanksPageBanner.classList.remove('modal--active');

        ///Send data on mail
      }, 2000);
    }
  });

  inputNamePageBanner.oninput = function () {
    if (inputNamePageBanner.value.length < 2) {
      inputNamePageBanner.classList.add('novalid');
    } else {
      inputNamePageBanner.classList.remove('novalid');
      inputNamePageBanner.classList.remove('error');
    }
  };

  inputPhonePageBanner.oninput = function () {
    if (inputPhonePageBanner.value.length < 16) {
      inputPhonePageBanner.classList.add('novalid');
    } else {
      inputPhonePageBanner.classList.remove('novalid');
      inputPhonePageBanner.classList.remove('error');
    }
  };
});